.appstore{
  width:180px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  margin-right: 10px;
}



.checkIcon{
  margin-top: 40px;
  width:70px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.submissionText{
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
  font-weight: 400;
  font-size: 1.4rem;
}
.emojiMarginTop{
  vertical-align: middle;
  margin-left: 2px;
  margin-bottom: 0px;
}

.error{
  padding-top: 20px;
  font-weight: 600;
  text-align: center;
  color:#de0043
}

.center{
  text-align: center;
  align-items: center;
}

.options{
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
}

.offerIcon{
    width:110px;
    margin-bottom: 10px;
}

.td{
    padding-bottom: 40px;
    font-size: 1.3rem;
    margin-right: 5px;
    font-weight: 600;
  }

  .benefitsList{
    margin-top: 50px;
    margin-right: 0px;
    display: inline-block;
  }
  
  .checkIconSmall{
    width:44px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }

  .saveButton{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.1rem;
    padding:12px;
    padding-bottom: 10px;
    background: linear-gradient(45deg,#446BF7,#45009D);
    border-radius: 10px;
    color:white;
    margin-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 5px;
    margin-right: 5px;
    align-self: center;
    align-items: center;
    text-align: center;
    display: inline-block;
    width:300px;
  }
  .saveButton:hover{
    cursor:pointer;
    opacity:0.7;
  }

  .query{
    margin-top: 30px;
    min-width: 18vw;
    max-width: 18vw;
    min-height: 100px;
    max-height: 100px;
    box-shadow: 0px 0px 6px 1px #9cb3ff;
    border: 0px solid #446BF7;
    border-radius: 8px;
    padding:10px;
    font-family: "Montserrat", sans-serif;
  }


  .full{
    position: fixed;
    left:0px;
    top:0px;
    width:100%;
    height:100%;
  }

  .countdownWidget{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width:60%;
    height:75%;
    background:#FFFFFFEB;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
  }

  .widgetLeft{
    flex:1;
    text-align: center;
    align-items: center;
    border-right:1px solid #DCDCDC;
  }
  
  .widgetRight{
    flex:1;
  }

  .profileImage{
    width:100px;
    height:100px;
    border-radius: 200px;
    border: 3px solid #512eff;
    margin-top:30px;
  }

  .widgetTitle{
    margin-top: 20px;
    font-size: 2rem;
    color:#484848;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
  }

  .tripLength{
    border-radius: 100px;
    padding:8px;
    background:#e30044;
    color:white;
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
  }

  .countdownTimer{
    margin-left:10px;
    margin-right: 10px;
    display:flex;
    flex-direction: row;
    margin-top: 50px;
  }

  .countdownBlock{
    flex:1;
    margin:5px;
    border-radius: 10px;
    padding:10px;
    background:#512eff;
    color:white;
  }

  .countdownTime{
    font-size: 2rem;
    font-weight: bold;
  }

  .countdownMeasure{
    font-size: 1.1rem;
  }

  .recommendationsTitle{
    margin-top: 20px;
    font-size: 1.8rem;
    color:#484848;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    padding:20px
  }

  .recommendation{
    box-shadow: 0px 0px 6px 1px #9cb3ff78;
    margin:20px;
    padding:10px;
    background: white;
    border-radius: 10px;
  }

  .recommendationName{
    margin-top: 0px;
    font-size: 1rem;
    color:#484848;
    font-family: "Montserrat", sans-serif;
    display: inline-block;
    vertical-align: middle;
    font-weight: bold;
    margin-right: 10px;
    margin-left: 10px;
  }

  .recommendationType{
    display: inline-block;
    vertical-align: middle;
    margin:5px;
    border-radius: 200px;
    padding:5px;
    padding-left:10px;
    padding-right: 10px;
    background:#512eff98;
    color:white;
    font-size: 0.9rem;
  }

  .recommendationMessage{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
  }

  .recommendationUpvotes{
    margin-top: 16px;
    margin-bottom: 10px;
    margin-left: 10px;
  }

  .upvotesIcon{
    display: inline-block;
    vertical-align: middle;
    width:14px;
    height:14px;
    margin-right: 0px;
    margin-top: -2px;
  }

  .recommendationButton{
    align-self: center;
    display: inline-block;
    vertical-align: middle;
    margin:5px;
    border-radius: 10px;
    padding:10px;
    padding-left:16px;
    padding-right: 16px;
    background:#512eff;
    color:white;
    font-size: 0.9rem;
    margin-top: 10px;
  }
  .recommendationButton:hover{
    cursor:pointer;
  }

  .centerButton{
    text-align: center;
    align-items: center;
  }
  
  .bookIcon{
    display: inline-block;
    vertical-align: middle;
    width:14px;
    height:16px;
    margin-left: 5px;
  }
  

/* MOBILE */

@media all and (max-width: 700px) {

}