@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}

@keyframes float2 {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}

.mobileOnly{
    display: none;
}

.selectSearchTitle{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 1rem;
    margin-top:20px;
}


.trustedBy{
    display:flex;
    flex-direction:row;
    padding: 10px;
    padding-top: 10px;
    padding-bottom:10px;
    border-radius: 10px;
    margin-top: 60px;
    justify-content: center;
    align-items: center;
    text-align: center;
    background:#ebebfc;
    width:80%;
    position: relative;
    left:calc(50% - 10px);
    transform: translate(-50%, 0%);
}

.trustedByClients{
    height:40px;
    margin-right: 20px;
}

.trustedByStars{
    margin-bottom:10px;
}

.trustedByText{
    color:#181818;
    font-size: 0.9rem;
}

.reviews{
  display: flex;
  flex-direction: row;
  margin-top: 40px;
}

.review{
   box-shadow: 0px 0px 6px 1px #9cb3ff;
   padding:20px;
   padding-top: 40px;
   padding-bottom:40px;
   border-radius: 20px;
   margin:20px;
   margin-bottom: 0px;
   flex:1;

}

.reviewImage{
    height:120px;
    border-radius: 20px;
    border: 3px solid #45009D;
}

.reviewName{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.4rem;
    margin-top: 30px;
}

.reviewLocation{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 1.1rem;
    color:#BCBCBC;
}

.reviewStars{
    margin: 20px;
    margin-bottom: 20px;
}

.reviewText{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 1rem;
    color:#000000;
    margin-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
}

.emoji{
  vertical-align: middle;
  margin-left: 2px;
  margin-top: -1px;
}

.header{
    width:'100%';
    padding-top:8px;
    padding-bottom:0px;
    display: flex;
    flex-direction: row;
}

.headerLogo{
    margin-top: 10px;
    margin-left: 30px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.8rem;
    font-style: italic;
    display: inline-block;
    vertical-align: middle;
}

.headerLogoImage{
    height:70px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;
    margin-bottom: 8px;
}

.headerButtons{
    display: inline-block;
    text-align: right;
    padding-right: 20px;
    flex:1;
}

.headerMenuItem{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 1rem;
    display: inline-block;
    vertical-align: middle;
    margin-left: 14px;
    margin-right: 14px;
}
.headerMenuItem:hover{
    cursor: pointer;
    opacity: 0.5;
} 

.headerSignupButton{
    margin-left: 20px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.1rem;
    padding:12px;
    padding-left: 40px;
    padding-right: 40px;
    background: linear-gradient(45deg,#446BF7,#45009D);
    border-radius: 10px;
    color:white;
    display:inline-block;
    margin-top: 10px;
}

.headerSignupButton:hover{
    cursor: pointer;
    opacity:0.7;
    border-radius: 12px;
}

.documentsWindowContainer{
  position: fixed;
  top:0px;
  left:0px;
  width:100%;
  height:100%;
  background:#484848CD;
  z-index:2000;
}

.documentsWindowContent{
    padding-bottom: 20px;
}

.documentsWidget{
    min-height: 90%;
    background:white;
    border-radius: 20px;
    box-shadow: 0px 0px 6px 1px #9cb3ff;
    padding-bottom:40px;
}

.matchingWidget{
    min-height: 90%;
    background:white;
    border-radius: 20px;
    box-shadow: 0px 0px 6px 1px #9cb3ff;
    padding-bottom:40px;
}

.documentsWindow{
  position: absolute;
  top:50%;
  left:50%;
  width:50%;
  min-height: 85%;
  max-height:90%;
  transform: translate(-50%,-50%);
  background:white;
  border-radius: 20px;
  overflow-y: auto;
}

.documentsWindowTitle{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.3rem;
    text-align: center;
    padding: 24px;
}

.matchingTitle{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
    padding: 24px;
}

.closeIcon{
    position: fixed;
    top:16px;
    right:16px;
    width:32px;
}

.submitButtonContainer{
    align-items: center;
    width:100%;
}

.radio{
    padding:10px;
    padding-top: 20px;
}

.radioOptions{
    align-items: center;
    text-align: center;
    justify-content: center;
    display:flex;
    margin-top: 10px;
    padding-left: 10px;
    padding-right:10px;
}

.radioOption{
    font-family: "Montserrat", sans-serif;
    border:1px solid #5603fc;
    color: #5603fc;
    background:white;
    font-weight: 500;
    border-radius: 6px;
    padding:10px;
    margin:10px;
    margin-left: 16px;
    margin-right: 16px;
    flex:1;
}

.radioOption:hover{
    cursor:pointer;
}

.radioOptionSelected{
    font-family: "Montserrat", sans-serif;
    border:1px solid #5603fc;
    background: #5603fc;
    color:white;
    font-weight: 500;
    border-radius: 6px;
    padding:10px;
    margin:10px;
    margin-left: 16px;
    margin-right: 16px;
    flex:1;
}

.radioOptionSelected:hover{
    cursor:pointer;
}


.radioQuestion{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 1.1rem;
    text-align: center;
}

.rangeCurrency{
    font-family: "Montserrat", sans-serif;
    font-size: 1.3rem;
    margin-right: 10px;
    margin-top: 10px;
}

.rangeInput{
    padding:8px;
    width:300px;
    border-radius: 4px;
    box-shadow: 0px 0px 6px 1px #9cb3ff;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    border:0px;
    font-size: 1.3rem;
    margin-top: 10px;
    margin-right: 20px;
}

.countryInput{
    width:320px;
    border-radius: 8px;
    box-shadow: 0px 0px 6px 1px #9cb3ff;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    border:0px;
    font-size: 1.3rem;
    margin-top: 20px;
    margin-right: 10px;
    align-self: center;
    position: relative;
    left:50%;
    transform: translate(-50%,0%);
}

.formText{
    padding-top:5px;
    padding-bottom:5px;
    width:320px;
    border-radius: 8px;
    box-shadow: 0px 0px 6px 1px #9cb3ff;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    border:0px;
    font-size: 1.3rem;
    margin-top: 20px;
    margin-right: 10px;
    align-self: center;
    padding-left: 5px;
}

.friends{
    height:28vw;
    border-radius: 20px;
    margin-top:-5vh;
    display: inline;
}

.rangeInput:focus{
    outline:none;
}

.landingContainer{
    display: flex;
    margin-top: 10vh;
}

.landingContainerLeft{
    flex:1;
}

.landingContainerRight{
    flex:1;
    display: flex;
    padding-right: 30px;
    padding-left: 30px;
}
.landingContainerRightMobile{
    display: none;
}

.flowColumnLeft{
    flex:1;
    margin:25px;
}

.flowColumnRight{
    flex:1;
    margin:25px;
    margin-top: 20vh;
}

.flowCard{
    border-radius: 16px;
    padding:20px;
    align-items: center;
    text-align: center;
    margin-top: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 200px;
}

.flowCardIcon{
    margin-top: 20px;
    width:40px;
}

.flowCardTitle{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 1.2rem;
    text-align: center;
    padding: 10px;
    margin-top: 30px;
}

#flowCard1{
    box-shadow: 0px 0px 6px 1px #9cb3ff;
}

#flowCard2{
    box-shadow: 0px 0px 6px 1px #9c9eff;
}

#flowCard3{
    box-shadow: 0px 0px 6px 1px #ae9cff;
}

#flowCard4{
    box-shadow: 0px 0px 6px 1px #e8bfff;
}

.registerCard{
    border-radius: 16px;
    padding:20px;
    padding-left: 10px;
    align-items: center;
    text-align: center;
    margin-top: 0vh;
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 200px;
    margin-left: 50px;
    margin-right: 50px;
}

.registerCardTitle{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-size: 2.1rem;
    font-style: italic;
}

.registerCardSubtitle{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 1.4rem;
    padding:30px;
}

.registerCardEmail{
    padding:12px;
    width:320px;
    border-radius: 8px;
    box-shadow: 0px 0px 6px 1px #9cb3ff;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    border:0px;
    font-size: 1.3rem;
}

.formSubmitButton{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.1rem;
    padding:12px;
    background: linear-gradient(45deg,#446BF7,#45009D);
    border-radius: 10px;
    color:white;
    margin-top: 40px;
    width:300px;
    align-self: center;
    align-items: center;
    text-align: center;
    position: relative;
    left:50%;
    transform: translate(-50%,0%);
}
.formSubmitButton:hover{
    cursor:pointer;
    opacity:0.7;
}

.formSignupButton{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.1rem;
    padding:12px;
    background: linear-gradient(45deg,#446BF7,#45009D);
    border-radius: 10px;
    color:white;
    display:inline-block;
    margin-top: 10px;
    width:320px;
}

.registerCardEmail:focus{
    outline: none;
}

.formSignupButton:hover{
    cursor: pointer;
    opacity:0.7;
    border-radius: 12px;
}

.scrollGif{
    position: absolute;
    bottom:6px;
    left:50%;
    transform: translate(-50%, 0%);
    height:100px;
    border-radius: 20px;
}

.separator{
    position: relative;
    left:50%;
    transform: translate(-50%,0%);
    width:110px;
    background-color: #9cb3ff;
    height:2px;
    border-radius: 100px;
    align-self: center;
    margin-bottom: 16px;
}

.howItWorks{
    position: relative;
    text-align: center;
    margin-top: 120px;
    align-items: center;
    justify-content: center;
}

.sectionTitle{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-size: 2.1rem;
    font-style: italic;
}

.sectionSubtitle{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 1.7rem;
    padding-top: 20px;
    width:60%;
    text-align: center;
    position: relative;
    left:20%;
}

.workCard{
    display: flex;
    margin-top: 40px;
    margin-left: 40px;
    width:80%;
}

.workCardIcon{
    width:50px;
    height:50px;
}

.workCardText{
    margin-left: 20px;
}

.workCardTitle{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-size: 1.6rem;
    text-align: left;
}

.workCardSubtitle{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 1.2rem;
    margin-top: 12px;
    margin-bottom:10px;
    text-align: left;
}

.sectionColumns{
    display:flex;
    margin-top: 60px;
}

.sectionColumnLeft{
    flex:1;
    padding-left:40px;
    padding-right:40px;
}

.sectionColumnRight{
    flex:1;
    padding-left:40px;
    padding-right:40px;
}

.sectionColumnLeftCountry{
    flex:1;
    padding-left:40px;
    padding-right:40px;
}

.sectionColumnRightCountry{
    flex:1;
    padding-left:40px;
    padding-right:60px;
}

.headshotsContainer{
  width: 85%;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  justify-content: space-between;
  padding: 20px;
  margin-left: 20px;
  display:flex;
}

.headshot{
    border: 3px solid #9cb3ff;
    border-radius: 20px;
    width: calc(6vw + 28px);
    height: calc(6vw + 28px);
    display: inline-block;
    vertical-align: middle;
    margin-top: 20px;
    margin-left: 20px;
    margin-right:20px;
    margin-bottom:20px;
}


.accountantQuote{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 1.4rem;
    flex:1;
}


.mockup{
    width:65%;
    border-radius: 20px;
    padding: 20px;
    margin-top: 0px;
    margin-right: 30px;
    margin-top: 0px;
}

.countries{
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-top: 80px;
}

.country{
    text-align: center;
    display: inline-block;
    margin-left: 30px;
    margin-right: 30px;
}

.countryIcon{
    height:28vw
}

.countryTitle{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-size: 2.2rem;
    margin-top: 30px;
}

.bottomTriangle{
    width:100%;
    height:105%;
    padding:0px;
    position: absolute;
    bottom:0px;
    left:0px;
    z-index:-1;
}

.footerCard{
    width:40%;
    background:white;
    border-radius: 20px;
    padding:24px;
    padding-top:50px;
    padding-bottom:60px;
    box-shadow: 0px 0px 6px 1px #9cb3ff;
    z-index:200;
    position:relative;
    left:50%;
    transform: translate(-50%, 80px);
    margin-top: 40px;
}

.footer{
   background: linear-gradient(0deg,#003DD9, #3600D7);
   width:100%;
   padding-top: 120px;
   padding-bottom: 60px;
   margin-top: 0px;
}

.footerMenu{
    margin-left: 100px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    color:white;
}


.footerCopyright{
    margin-left: 100px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    color:white;
    font-size: 1.2rem;
    margin-top: 40px;
}

.footerMenu a{
    text-decoration: none;
    color:white;
    font-size: 1.7rem;
}

.offersMenu{
    display:flex;
    flex-direction:row;
    margin-top: 100px;
    margin-bottom: 60px;
}

.offersMenuItemUp{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.1rem;
    padding:30px;
    background: linear-gradient(45deg,#446BF7,#45009D);
    flex:1;
    margin-left: 20px;
    margin-right: 20px;
    border-radius:20px;
    color:white;
    animation: float 6s ease-in-out infinite;
}

.offersMenuItemDown{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 1.1rem;
    padding:30px;
    background: linear-gradient(45deg,#446BF7,#45009D);
    flex:1;
    margin-left: 20px;
    margin-right: 20px;
    border-radius:20px;
    color:white;
    animation: float2 6s ease-in-out infinite;
}


.beach{
    padding:0 !important;
    height:27.2vw;
    width:100%;
    position: relative;
    top:50%;
    transform: translate(0%,-50%);
    border-radius: 30px;
    object-fit: cover;
    display: inline-block;
}

/* MOBILE */

@media all and (max-width: 700px) {

    .mobileOnly{
        display: inline-block;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .documentsWindow{
        position: absolute;
        top:50%;
        left:50%;
        width:90%;
        min-height: 85%;
        max-height:90%;
        transform: translate(-50%,-50%);
        background:white;
        border-radius: 20px;
        overflow-y: auto;
      }

    .offersMenu{
        display:block;
        flex-direction:row;
        margin-top: 50px;
        text-align: center;
    }
    
    .offersMenuItem{
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 500;
        font-size: 1rem;
        padding:12px;
        background: linear-gradient(45deg,#446BF7,#45009D);
        flex:1;
        margin-left: 10px;
        margin-right: 10px;
        border-radius:20px;
        color:white;
        width:90%;
        margin-bottom: 20px;
    }

    .headerMenuItem{
        display:none;
    }

    .formSubmitButton{
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 500;
        font-size: 1rem;
        padding:12px;
        background: linear-gradient(45deg,#446BF7,#45009D);
        border-radius: 10px;
        color:white;
        margin-top: 40px;
        width:220px;
        align-self: center;
        align-items: center;
        text-align: center;
        position: relative;
        left:50%;
        transform: translate(-50%,0%);
    }
    .formSubmitButton:hover{
        cursor:pointer;
        opacity:0.7;
    }

    .rangeInput{
        padding:8px;
        width:220px;
        max-width: 95%;
        border-radius: 4px;
        box-shadow: 0px 0px 6px 1px #9cb3ff;
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        border:0px;
        font-size: 1.1rem;
        margin-top: 10px;
        margin-right: 0px;
    }
    
    .countryInput{
        width:240px;
        max-width: 95%;
        border-radius: 8px;
        box-shadow: 0px 0px 6px 1px #9cb3ff;
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        border:0px;
        font-size: 1.1rem;
        margin-top: 20px;
        margin-right: 0px;
        align-self: center;
        position: relative;
        left:50%;
        transform: translate(-50%,0%);
    }
    
    .rangeInput:focus{
        outline:none;
    }

    .matchingWidget{
        min-height: 90%;
        background:white;
        border-radius: 20px;
        box-shadow: 0px 0px 6px 1px #9cb3ff;
        padding-bottom:40px;
        max-width: 92%;
        position: relative;
        left:5%;
    }
    

    .documentsWidget{
        min-height: 90%;
        background:white;
        border-radius: 20px;
        box-shadow: 0px 0px 6px 1px #9cb3ff;
        padding-bottom:40px;
        max-width: 100%;
    }

    .documentsWindowTitle{
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 600;
        font-size: 1.3rem;
        text-align: center;
        padding: 24px;
    }

    .matchingTitle{
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 600;
        font-size: 1.3rem;
        text-align: center;
        padding: 24px;
    }

    .trustedBy{
        display:inline-block;
        padding: 10px;
        padding-top: 10px;
        padding-bottom:10px;
        border-radius: 10px;
        margin-top: 50px;
        justify-content: center;
        align-items: center;
        text-align: center;
        background:#ebebfc;
        width:100%;
        position: absolute;
        margin-left: 5px;
    }
    
    .trustedByClients{
        height:40px;
        margin-right: 0px;
    }
    
    .trustedByStars{
        margin-bottom:10px;
    }
    
    .trustedByText{
        color:#181818;
        font-size: 0.9rem;
    }

    .headerLogo{
        margin-left: 0px;
        position:relative;
        left:50%;
        transform: translate(-50%,0%);
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 600;
        font-size: 1.4rem;
        font-style: italic;
        display: inline-block;
        vertical-align: middle;
    }
    
    .headerLogoImage{
        height:60px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        margin-bottom: 8px;
    }

    .app{
        overflow-x: hidden;
    }

    .trustedBy{
        margin-top: 40px;
        width:100%;
        position: relative;
        left:calc(50% - 10px);
        transform: translate(-50%, 0%);
    }

    .reviews{
        display: inline-block;
        margin-top: 40px;
      }
      
      .review{
         box-shadow: 0px 0px 6px 1px #9cb3ff;
         padding:20px;
         padding-top: 20px;
         padding-bottom:20px;
         border-radius: 20px;
         margin:20px;
         margin-bottom: 0px;
      
      }
      
      .reviewImage{
          height:80px;
          border-radius: 20px;
          border: 3px solid #45009D;
      }
      
      .reviewName{
          font-family: "Montserrat", sans-serif;
          font-optical-sizing: auto;
          font-weight: 600;
          font-size: 1.3rem;
          margin-top: 30px;
      }
      
      .reviewLocation{
          font-family: "Montserrat", sans-serif;
          font-optical-sizing: auto;
          font-weight: 500;
          font-size: 1.1rem;
          color:#BCBCBC;
      }
      
      .reviewStars{
          margin: 20px;
          margin-bottom: 20px;
      }
      
      .reviewText{
          font-family: "Montserrat", sans-serif;
          font-optical-sizing: auto;
          font-weight: 400;
          font-size: 1rem;
          color:#000000;
          margin-top: 20px;
          padding-left: 10px;
          padding-right: 10px;
      }

    .radioOptions{
        align-items: center;
        text-align: center;
        justify-content: center;
        display:inline-block;
        margin-top: 10px;
        padding-left: 10px;
        padding-right:10px;
    }
    
    .radioOption{
        font-family: "Montserrat", sans-serif;
        border:1px solid #5603fc;
        color: #5603fc;
        background:white;
        font-weight: 500;
        border-radius: 6px;
        padding:10px;
        margin:10px;
        margin-left: 6px;
        margin-right: 6px;
    }

    .radioOption{
        margin-left: 5px;
        margin-right: 5px;
    }
    
    .radioOptionSelected{
        margin-left: 5px;
        margin-right: 5px;
    }

    .headerButtons{
        display:none;
    }

    .landingContainer{
        display: inline-block;
        max-width: 100%;
        margin-top: 2vh;
    }
    
    .landingContainerLeft{
        flex:1;
    }
    
    .landingContainerRight{
        display: none;
    }

    .landingContainerRightMobile{
        flex:1;
        display: inline-block;
        padding-right: 20px;
        padding-left: 20px;
    }
    
    .flowColumnLeft{
        flex:1;
        margin:0px;
    }
    
    .flowColumnRight{
        flex:1;
        margin:0px;
        margin-top: 4vh;
    }

    .scrollGif{
        display: none;
    }

    .registerCard{
        border-radius: 16px;
        padding:0px;
        padding-left: 10px;
        align-items: center;
        text-align: center;
        margin-top: 10vh;
        padding-top: 10px;
        padding-bottom: 40px;
        min-height: 200px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .registerCardTitle{
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 700;
        font-size: 1.6rem;
        font-style: italic;
        padding-left: 0px;
        padding-right: 0px;
    }
    
    .registerCardSubtitle{
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        font-size: 1.3rem;
        padding:10px;
        padding-left: 0px;
        padding-right: 0px;
    }
    
    .registerCardEmail{
        padding:12px;
        width:320px;
        max-width: 90%;
        margin-top: 20px;
        border-radius: 8px;
        box-shadow: 0px 0px 6px 1px #9cb3ff;
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        border:0px;
        font-size: 1rem;
    }

    .formSignupButton{
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 600;
        font-size: 1rem;
        padding:12px;
        background: linear-gradient(45deg,#446BF7,#45009D);
        border-radius: 10px;
        color:white;
        display:inline-block;
        margin-top: 10px;
        width:320px;
        max-width:90%;  
    }


    .howItWorks{
        margin-top: 50px;
    }

    .sectionColumns{
        display:inline-block;
        margin-top: 20px;
    }
    
    .sectionColumnLeft{
        flex:1;
        padding-left:20px;
        padding-right:20px;
    }
    
    .sectionColumnRight{
        flex:1;
        padding-left:20px;
        padding-right:20px;
    }

    .sectionColumnLeftCountry{
        display:none;
    }
    
    .sectionColumnRightCountry{
        flex:1;
        margin-top:40px;
        padding-left:0px;
        padding-right:0px;
    }

    .workCard{
        display: flex;
        margin-top: 30px;
        margin-left: 20px;
        margin-right: 20px;
        width:90%;
    }
    
    .workCardIcon{
        width:36px;
        height:36px;
    }
    
    .workCardText{
        margin-left: 20px;
    }
    
    .workCardTitle{
        font-size: 1.2rem;
    }
    
    .workCardSubtitle{
        display:none;
    }

    .headshotsContainer{
        width: 85%;
        align-items: center;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 20px;
        justify-content: space-between;
        padding: 20px;
      }
      
      .headshot{
          border: 3px solid #9cb3ff;
          border-radius: 20px;
          width: calc(8vw + 20px);
          height: calc(8vw + 20px);
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px;
          margin-right:10px;
          margin-bottom:10px;
      }
      
      .mockup{
          width:85%;
          border-radius: 20px;
      }
      
      .countries{
          padding-top: 80px;
      }
      
      .country{
          width:35%;
          text-align: center;
          display: inline-block;
          margin-left: 30px;
          margin-right: 30px;
          margin-bottom: 20px;
      }
      
      .countryIcon{
          height:24vw
      }
      
      .countryTitle{
          font-family: "Montserrat", sans-serif;
          font-optical-sizing: auto;
          font-weight: 700;
          font-size: 1.6rem;
          margin-top: 30px;
      }
      .sectionTitle{
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 600;
        font-size: 1.7rem;
        font-style: italic;
    }

      .sectionSubtitle{
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 500;
        font-size: 1.3rem;
        padding-top: 20px;
        width:80%;
        text-align: center;
        position: relative;
        left:10%;
    }

    .footerCard{
        width:80%;
        background:white;
        border-radius: 20px;
        padding:24px;
        padding-top:50px;
        padding-bottom:60px;
        box-shadow: 0px 0px 6px 1px #9cb3ff;
        z-index:200;
        position:relative;
        left:calc(10% - 24px);
        transform: translate(0%, 60px);
        margin-top: 0px;
    }
    
    .footer{
       background: linear-gradient(0deg,#003DD9, #3600D7);
       width:100%;
       padding-top: 120px;
       padding-bottom: 60px;
       margin-top: 0px;
    }
    
    .footerMenu{
        margin-left: 50px;
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 500;
        color:white;
    }
    
    
    .footerCopyright{
        margin-left: 50px;
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 500;
        color:white;
        font-size: 1.1rem;
        margin-top: 40px;
        max-width:70%;
    }
    
    .footerMenu a{
        text-decoration: none;
        color:white;
        font-size: 1.2rem;
    }
    
    
}

